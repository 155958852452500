import { CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

interface AuthorizedRoomRouteProps extends RouteProps {
  component: React.ComponentType<any>; // Type du composant fourni
}

const AuthorizedRoomRoute: React.FC<AuthorizedRoomRouteProps> = ({ component: Component, ...rest }) => {
  const [authorizedRooms, setAuthorizedRooms] = useState<string[]>([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  // Fetch la liste des rooms
  useEffect(() => {
    const fetchAuthorizedRooms = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_BACKEND + '/rooms');
        if (response.ok) {
          const data = await response.json();
          setAuthorizedRooms(data.map((room: { unique_name: string }) => room.unique_name));
          setDataLoaded(true);
        } else {
          console.log('Erreur lors de la récupération des rooms autorisées');
        }
      } catch (error) {
        console.log('Erreur lors de la récupération des rooms autorisées', error);
      }
    };

    fetchAuthorizedRooms();
  }, []);

  if (!dataLoaded) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Chargement...
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const { match } = props;
        const { URLRoomName } = match.params as { URLRoomName: string };

        if (authorizedRooms.includes(URLRoomName)) {
          return <Component {...props} />;
        } else {
          // Rediriger vers un composant de votre choix en cas de non autorisation
          return <Redirect to="/non-autorise" />;
        }
      }}
    />
  );
};

export default AuthorizedRoomRoute;