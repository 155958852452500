import React, { useState } from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
  let headline = '';
  let message = '';

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      headline = 'Impossible d\'accéder au média:';
      message =
        'L\'utilisateur a refusé l\'accès à la vidéo. Veuillez autoriser le navigateur à accéder à la caméra.';
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      headline = 'Impossible d\'accéder au média:';
      message =
        'L\'utilisateur a refusé l\'accès à l\'audio. Veuillez autoriser le navigateur à accéder au microphone.';
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = 'Impossible d\'accéder au média:';

      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message =
          'Le système à empêché le navigateur a accéder au microphone ou à la caméra. Veuillez vérifier vos paramètres système.';
      } else {
        message =
          'L\'utilisateur a refusé l\'accès à l\'audio et à la vidéo. Veuillez autoriser le navigateur à accéder au microphone et à la caméra';
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = 'Impossible de trouver le microphone ou la caméra:';
      message =
        'Le navigateur ne peut pas accéder au microphone ou à la caméra. Veuillez vérifier que tous vos périphériques d\'entrée sont connectés et activés.';
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = 'Erreur lors de l\'acquisition du média:';
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = 'Pas de caméra et de microphone détecté:';
      message = 'Les autres participants ne pourront ni vous voir, ni vous entendre.';
      break;

    case !hasVideo:
      headline = 'Pas de caméra détectée:';
      message = 'Les autres participants ne pourront pas vous voir.';
      break;

    case !hasAudio:
      headline = 'Pas de microphone détecté:';
      message = 'Les autres participants ne pourront pas vous entendre.';
  }

  return {
    headline,
    message,
  };
}

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
